export enum BreadcrumbSizeOption {
	Small = 'sm',
	Medium = 'md',
	Large = 'lg',
	Xl = 'xl',
	XXl = 'xxl'
}

export const breadcrumbSizeToClassMap = new Map([
	[BreadcrumbSizeOption.Small, 'sl-typography-body-small'],
	[BreadcrumbSizeOption.Medium, 'sl-typography-body-medium'],
	[BreadcrumbSizeOption.Large, 'sl-typography-body-large'],
	[BreadcrumbSizeOption.Xl, 'sl-typography-body-xl'],
	[BreadcrumbSizeOption.XXl, 'sl-typography-body-xxl']
]);
