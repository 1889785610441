import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SkeletonModule } from 'primeng/skeleton';

import { BreadcrumbSizeOption, breadcrumbSizeToClassMap as BreadcrumbSizeToClassMap } from './models/breadcrumbs.model';

@Component({
	selector: 'sl-breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	styleUrl: './breadcrumbs.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [BreadcrumbModule, NgClass, SkeletonModule]
})
export class BreadcrumbsComponent {
	readonly itemsSignal$ = input<MenuItem[]>(null, { alias: 'items' });
	readonly sizeSignal$ = input<BreadcrumbSizeOption>(null, { alias: 'size' });

	readonly breadcrumbsClassSignal$ = computed(() => BreadcrumbSizeToClassMap.get(this.sizeSignal$()));
}
